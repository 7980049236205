import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import AOS from 'aos'
import "aos/dist/aos.css"
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'


const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);

const Slide1Header1 = () => <TranslatedMessage id="Slide1Header1" />
const Slide1Header2 = () => <TranslatedMessage id="Slide1Header2" />
const Slide1P1 = () => <TranslatedMessage id="Slide1P1" />
const Slide1P2 = () => <TranslatedMessage id="Slide1P2" />

const Slide2Header1 = () => <TranslatedMessage id="Slide2Header1" />
const Slide2Header2 = () => <TranslatedMessage id="Slide2Header2" />
const Slide2Header3 = () => <TranslatedMessage id="Slide2Header3" />
const Slide2P1 = () => <TranslatedMessage id="Slide2P1" />
const Slide2Li1 = () => <TranslatedMessage id="Slide2Li1" />
const Slide2Li2 = () => <TranslatedMessage id="Slide2Li2" />
const Slide2Li3 = () => <TranslatedMessage id="Slide2Li3" />

const Slide3Header1 = () => <TranslatedMessage id="Slide3Header1" />
const Slide3Header2 = () => <TranslatedMessage id="Slide3Header2" />
const Slide3Header3 = () => <TranslatedMessage id="Slide3Header3" />
const Slide3P1 = () => <TranslatedMessage id="Slide3P1" />
const Slide3Li1 = () => <TranslatedMessage id="Slide3Li1" />
const Slide3Li2 = () => <TranslatedMessage id="Slide3Li2" />

const Slide4Header1 = () => <TranslatedMessage id="Slide4Header1" />
const Slide4Header2 = () => <TranslatedMessage id="Slide4Header2" />
const Slide4Header3 = () => <TranslatedMessage id="Slide4Header3" />
const Slide4P1 = () => <TranslatedMessage id="Slide4P1" />
const Slide4Li1 = () => <TranslatedMessage id="Slide4Li1" />
const Slide4Li2 = () => <TranslatedMessage id="Slide4Li2" />
const Slide4Li3 = () => <TranslatedMessage id="Slide4Li3" />

const Slide5Header1 = () => <TranslatedMessage id="Slide5Header1" />
const Slide5Header2 = () => <TranslatedMessage id="Slide5Header2" />
const Slide5P1 = () => <TranslatedMessage id="Slide5P1" />
const Slide5Li1 = () => <TranslatedMessage id="Slide5Li1" />
const Slide5Li2 = () => <TranslatedMessage id="Slide5Li2" />
const Slide5Li3 = () => <TranslatedMessage id="Slide5Li3" />

const Slide6Header1 = () => <TranslatedMessage id="Slide6Header1" />
const Slide6Header2 = () => <TranslatedMessage id="Slide6Header2" />
const Slide6P1 = () => <TranslatedMessage id="Slide6P1" />
const Slide6Li1 = () => <TranslatedMessage id="Slide6Li1" />
const Slide6Li2 = () => <TranslatedMessage id="Slide6Li2" />
const Slide6Li3 = () => <TranslatedMessage id="Slide6Li3" />

const Slide7Header1 = () => <TranslatedMessage id="Slide7Header1" />
const Slide7Header2 = () => <TranslatedMessage id="Slide7Header2" />
const Slide7P1 = () => <TranslatedMessage id="Slide7P1" />
const Slide7Li1 = () => <TranslatedMessage id="Slide7Li1" />
const Slide7Li2 = () => <TranslatedMessage id="Slide7Li2" />
const Slide7Li3 = () => <TranslatedMessage id="Slide7Li3" />

const Slide8Header1 = () => <TranslatedMessage id="Slide8Header1" />
const Slide8Header2 = () => <TranslatedMessage id="Slide8Header2" />
const Slide8P1 = () => <TranslatedMessage id="Slide8P1" />
const Slide8Li1 = () => <TranslatedMessage id="Slide8Li1" />
const Slide8Li2 = () => <TranslatedMessage id="Slide8Li2" />
const Slide8Li3 = () => <TranslatedMessage id="Slide8Li3" />

const headersAndVideos = [
    {
      header: (
          <Col className='headerContainer'>
            <h1 className="display-5 entryHeader">
              <div><Slide1Header1/><span className="text-primary"><Slide1Header2/></span>.</div>
              
            </h1>
            <p className="lead pt-3 text-center entryText"><Slide1P1/><br/><Slide1P2/></p>
          </Col>
      ),
      videoPath: ['/videos/3d-flyover-of-crane-app.mp4', '/videos/3d-flyover-of-crane-app.mp4'],
    },
    {
      header: (
          <Col className='headerContainer'>
            <h1 className="display-5">
              <div><Slide2Header1/><span className="text-primary"><Slide2Header2/></span><Slide2Header3/></div>
              
            </h1>
            <p className="pt-0 pb-0 text-center"><Slide2P1/></p>
            <ul className="text-start headerList pt-0">
              <li><Slide2Li1/></li>
              <li><Slide2Li2/></li>
              <li><Slide2Li3/></li>
            </ul>
          </Col>
      ),
      videoPath: ['/videos/find-best-locations-for-cranes.mp4','/videos/find-best-locations-for-cranes-mobile.mp4'],
    },
    {
      header: (
        <Col className='headerContainer'>
        <h1 className="display-5">
          <div><Slide3Header1/><span className="text-primary"><Slide3Header2/></span><Slide3Header3/></div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide3P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide3Li1/></li>
          <li><Slide3Li2/></li>
          <br></br>
        </ul>
      </Col>
      ),
      videoPath: ['/videos/compare-best-locations-for-cranes.mp4', '/videos/compare-best-locations-for-cranes-mobile.mp4'],
    },
    {
      header: (
        <Col className='headerContainer'>
        <h1 className="display-5">
          <div><Slide4Header1/><span className="text-primary"><Slide4Header2/></span><Slide4Header3/></div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide4P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide4Li1/></li>
          <li><Slide4Li2/></li>
          <li><Slide4Li3/></li>
        </ul>
      </Col>
      ),
      videoPath: ['/videos/find-the-right-cranes.mp4','/videos/find-the-right-cranes-mobile.mp4'],
    },
  {
      header: (
        <Col className='headerContainer'>
        <h1 className="display-5">
          <div><Slide5Header1/><span className="text-primary"><Slide5Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide5P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide5Li1/></li>
          <li><Slide5Li2/></li>
          <li><Slide5Li3/></li>
        </ul>
      </Col>
      ),
      videoPath: ['/videos/optimize-structural-design.mp4','/videos/optimize-structural-design-mobile.mp4'],
    },
  {
      header: (
        <Col className='headerContainer'>
        <h1 className="display-5">
          <div><Slide6Header1/><span className="text-primary"><Slide6Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide6P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide6Li1/></li>
          <li><Slide6Li2/></li>
          <li><Slide6Li3/></li>
          <br></br>
        </ul>
      </Col>
      ),
      videoPath: ['/videos/two-cranes-clash-check.mp4', '/videos/show-crane-manufacturer-data-mobile.mp4'],
    },
  {
      header: (
        <Col className='headerContainer'>
        <h1 className="display-5">
          <div><Slide7Header1/><span className="text-primary"><Slide7Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide7P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide7Li1/></li>
          <li><Slide7Li2/></li>
          <li><Slide7Li3/></li>
        </ul>
      </Col>
      ),
      videoPath: ['/videos/show-crane-radius.mp4', '/videos/bim-dashboard-online-app-mobile.mp4'],
    },
  {
      header: (
        <Col className='headerContainer'>
        <h1 className="display-5">
          <div><Slide8Header1/><span className="text-primary"><Slide8Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide8P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide8Li1/></li>
          <li><Slide8Li2/></li>
          <li><Slide8Li3/></li>
        </ul>
      </Col>
      ),
      videoPath: ['/videos/bim-dashboard-online-app.mp4', '/videos/bim-dashboard-online-app-mobile.mp4'],
    }
    
];

const VideoPlayer = ({ src, videoId, activeIndex  }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    

    const handleVisibilityChange = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && parseInt(entry.target.id[entry.target.id.length - 1]) === activeIndex) {
          video.currentTime = 0;
          video.play();
          setIsPlaying(true);
        } else {
          video.pause();
          setIsPlaying(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleVisibilityChange, {
      threshold: 1,
    });

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, [activeIndex]);

  const handlePlayPause = (e) => {
    const video = document.getElementById(`video-${videoId}`);
    const button = e.target;
    if (video.paused) {
      video.play();
      button.textContent = '||';
    } else {
      video.pause();
      button.textContent = '▷';
    }
  };

  return (
    <div className='videoContainer'>
      <video
        id={`video-${videoId}`}
        src={src}
        loop
        muted
        playsInline
        ref={videoRef}
      />
      <button  className='btn btn-sm btn-light pauseButton d-flex align-items-center text-center justify-content-center' onClick={handlePlayPause}>
        {isPlaying ? '||' : '▷'}
      </button>
    </div>
  );
};

const Home = () => {
    const [isPortraitMode, setIsPortraitMode] = useState(false);
    const [videoSource, setVideoSource] = useState(0);
    
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollPosRef = useRef(0);
    const containerRef = useRef(null);
    const headerRefs = useRef([]);
    
    const scrollDistanceRef = useRef(null)

    const touchDistanceRef = useRef(null)
    const touchDistanceCumulativeRef = useRef(null)
    const touchStartY = useRef(null);
    const touchEndY = useRef(null);
    const touchScrollReached = useRef(false)

    const wasLoaded = useRef(false)
    const wasPresented = useRef(false)
    const slideChanged = useRef(false)
    // let touchStartY = null;
    // let touchEndY = null;
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['HomePageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['HomePageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
      
    },[intl.locale])

    

    useEffect(() => {
      const handleMatchMediaChange = (e) => {
        const isPortrait = e.matches;
        setIsPortraitMode(isPortrait);
        setVideoSource(isPortrait ? 1 : 0);
      };
  
      const mediaQuery = window.matchMedia('(orientation: portrait) and (max-aspect-ratio: 9/16)');
      mediaQuery.addEventListener('change', handleMatchMediaChange);
      handleMatchMediaChange(mediaQuery);
  
      return () => {
        mediaQuery.removeEventListener('change', handleMatchMediaChange);
      };
    }, [isPortraitMode]);

    const getVideoPath = (videoPaths, source) => {
      return videoPaths[source];
    };

    const handleTouchStart = useCallback((event) => {
      const headerElement = headerRefs.current[activeIndex]
      touchStartY.current = event.touches[0].clientY
      touchDistanceRef.current = 0
      if(!headerElement || wasPresented.current){
        document.body.style.overflowY = 'overlay';
        window.removeEventListener('touchstart', handleTouchStart);
      }
    }, [activeIndex, touchStartY,  wasPresented])

    const handleTouchMove = useCallback((event) => {
      const container = containerRef.current;
      if(container.style.position === "absolute"){
        container.style.position = "fixed"
        container.style.top = "10vh"
      }
      const headers = headerRefs.current;
      const headerElement = headerRefs.current[activeIndex]
      const header = headerElement.querySelector(".headerContainer")
      header.style.transition = "none"

      const windowHeight = window.innerHeight
      document.body.style.overflowY = 'hidden';

      // check if first video or last video
      const firstVideo = activeIndex === 0 ? true : false
      const lastVideo = activeIndex === headers.length - 1  ? true : false
      
      // save y coordinate to ref
      touchEndY.current = event.touches[0].clientY

      // save previous distance to const
      const prevDistance = touchDistanceRef.current

      // calculate new distance
      const distance = touchStartY.current - touchEndY.current

      // save new dist to ref
      touchDistanceRef.current = distance

      // calcuate difference of previous and latest distances
      const distDiff = distance - prevDistance

      // Check if 'wheel' event listener is attached to the window
      const wheelListenerExists = window.onwheel !== null;
      if (wheelListenerExists) {
        // Remove 'wheel' event listener
        window.onwheel = null;
      }

      if(scrollDistanceRef.current > 0){
        scrollDistanceRef.current = 0
        touchDistanceCumulativeRef.current= 0
      }
      touchDistanceCumulativeRef.current += distDiff
      const currentCumulative = touchDistanceCumulativeRef.current
      
      const moveDown = prevDistance < distance ? true : false
      // console.log(moveDown)
      // console.log("start_y: " + touchStartY.current)
      // console.log("end_y: " + touchEndY.current)
      // console.log("distance: " + distance)
      // console.log("distance_cum: " + currentCumulative)
      // console.log("scroll: " + scrollDistanceRef.current)
      // console.log("active index: " + activeIndex)
      // console.log("slide change: " + slideChanged.current)
      

      if((headerElement && !wasPresented.current) ){
        wasPresented.current = false
        if(firstVideo){
          // prevent from going below 0
          if(currentCumulative <= 0){
            touchDistanceCumulativeRef.current = 0
              slideChanged.current = false
              header.style.top = `${touchDistanceCumulativeRef.current }px`
          }
          // move header
          else if(currentCumulative > 0 && currentCumulative <= (windowHeight * 0.9)){
              // touchDistanceRef.current += distance
              slideChanged.current = false
              header.style.top = `${currentCumulative}px`
          }
          // move to next
          else{
              wasLoaded.current = false
              slideChanged.current = true
              touchDistanceCumulativeRef.current = 0
              setActiveIndex(activeIndex + 1)
          }
          
          
        }
        else if(lastVideo){
            // if slide just changed, start from top
            if(slideChanged.current && moveDown){
                header.style.top = `${touchDistanceRef.current}px`
                slideChanged.current = false
            }
            // move to previous
            else if(currentCumulative < 0){
                touchDistanceCumulativeRef.current = (windowHeight * 0.9)
                wasLoaded.current = false
                slideChanged.current = true
                setActiveIndex(activeIndex - 1)
            }
            // move header
            else if(currentCumulative >= 0 && currentCumulative <= (windowHeight * 0.9)){
                slideChanged.current = false
                header.style.top = `${currentCumulative}px`
            }
            // prevent from going over
            else{
                document.body.style.overflowY = 'overlay';
                container.style.position = "absolute"
                container.style.top = "0%"
                container.style.maxWidth = "80vw"
                wasPresented.current = true
                touchScrollReached.current = true
            }
        }
        else{
            // if slide just changed, start from top
            if(slideChanged.current && moveDown){
              header.style.top = `${touchDistanceCumulativeRef.current}px`
              slideChanged.current = false
            }
            // move to previous
            else if(currentCumulative < 0){
                touchDistanceCumulativeRef.current = (windowHeight * 0.9)
                wasLoaded.current = false
                slideChanged.current = true
                setActiveIndex(activeIndex - 1)
            }
            // move header
            else if(currentCumulative >= 0 && currentCumulative <= (windowHeight * 0.9)){
                slideChanged.current = false
                header.style.top = `${currentCumulative}px`
            }
            // move to next
            else if(currentCumulative > 0 && currentCumulative > (windowHeight * 0.9)){
                wasLoaded.current = false
                slideChanged.current = true
                touchDistanceCumulativeRef.current = 0
                setActiveIndex(activeIndex + 1)
            }
        }
      }
      else{
        // wasPresented.current = true
        // touchDistanceCumulativeRef.current = null
        document.body.style.overflowY = 'overlay';
        window.removeEventListener('mousemove', handleTouchMove);
        // console.log("events removed")
      } 

    }, [activeIndex, containerRef, headerRefs, touchDistanceRef, touchDistanceCumulativeRef, touchStartY, touchEndY,  wasPresented, wasLoaded])

    const handleScroll = useCallback((event) => {
        const container = containerRef.current;
        if(container.style.position === "absolute"){
          container.style.position = "fixed"
          container.style.top = "10vh"
        }
        const headers = headerRefs.current;
        // console.log("scroll: " + activeIndex)
        
        const headerElement = headerRefs.current[activeIndex]

            // check if header exists and presentation not finished
            if(headerElement && !wasPresented.current){
                document.body.style.overflowY = 'hidden';
                const header = headerElement.querySelector(".headerContainer")
                header.style.transition = "top 0.4s ease"
                const viewportHeight = window.innerHeight * 0.85

                // check if first video or last video
                const firstVideo = activeIndex === 0 ? true : false
                const lastVideo = activeIndex === headers.length - 1  ? true : false

                const moveDown = event.deltaY < 0 ? false : true

                // Check if 'wheel' event listener is attached to the window
                const touchListenerExists = window.ontouchmove !== null;
                if (touchListenerExists) {
                  // Remove 'wheel' event listener
                  window.ontouchmove = null;
                }

                if(touchDistanceCumulativeRef.current > 0){
                  touchDistanceCumulativeRef.current = 0
                  scrollDistanceRef.current = 0
                }

                if(firstVideo){
                    // prevent from going below 0
                    if(scrollDistanceRef.current + event.deltaY <= 0){
                        scrollDistanceRef.current = 0
                        slideChanged.current = false
                        header.style.top = `${scrollDistanceRef.current}px`
                    }
                    // move header
                    else if(scrollDistanceRef.current + event.deltaY > 0 && scrollDistanceRef.current + event.deltaY <= viewportHeight){
                        scrollDistanceRef.current += event.deltaY
                        slideChanged.current = false
                        header.style.top = `${scrollDistanceRef.current}px`
                    }
                    // move to next
                    else{
                        scrollDistanceRef.current = 0
                        wasLoaded.current = false
                        slideChanged.current = true
                        setActiveIndex(activeIndex + 1)
                    }
                }else if(lastVideo){
                    // if slide just changed, start from top
                    if(slideChanged.current && moveDown){
                        scrollDistanceRef.current = 100
                        header.style.top = `${scrollDistanceRef.current}px`
                        slideChanged.current = false
                    }
                    // move to previous
                    else if(scrollDistanceRef.current + event.deltaY < 0){
                        scrollDistanceRef.current = viewportHeight
                        wasLoaded.current = false
                        slideChanged.current = true
                        setActiveIndex(activeIndex - 1)
                    }
                    // move header
                    else if(scrollDistanceRef.current + event.deltaY >= 0 && scrollDistanceRef.current + event.deltaY <= viewportHeight){
                        scrollDistanceRef.current += event.deltaY
                        slideChanged.current = false
                        header.style.top = `${scrollDistanceRef.current}px`
                    }
                    // prevent from going over 600
                    else{
                        document.body.style.overflowY = 'overlay';
                        container.style.position = "absolute"
                        container.style.top = "0%"
                        container.style.maxWidth = "80vw"
                        wasPresented.current = true
                    }
                }else{
                    
                    // move to previous
                    if(scrollDistanceRef.current + event.deltaY < 0){
                        scrollDistanceRef.current = viewportHeight
                        wasLoaded.current = false
                        slideChanged.current = true
                        setActiveIndex(activeIndex - 1)
                    }
                    // move header
                    else if(scrollDistanceRef.current + event.deltaY >= 0 && scrollDistanceRef.current + event.deltaY <= viewportHeight){
                        scrollDistanceRef.current += event.deltaY
                        slideChanged.current = false
                        header.style.top = `${scrollDistanceRef.current}px`
                    }
                    // move to next
                    else{
                        scrollDistanceRef.current = 0
                        wasLoaded.current = false
                        slideChanged.current = true
                        setActiveIndex(activeIndex + 1)
                    }
                }
                
            }else{
                document.body.style.overflowY = 'overlay';
                window.removeEventListener('wheel', handleScroll);
            } 


    }, [activeIndex, containerRef, headerRefs, scrollDistanceRef, wasPresented, wasLoaded])

    // add fade in
    useEffect(() => {
        AOS.init({
            duration: 2000
        })
        // handleTouch()
    }, [activeIndex])

    // add event for scroll and touch whenever new data
    useEffect(() => {
        const container = containerRef.current;
        const headers = headerRefs.current;
        // console.log(wasLoaded.current)
        // attach event if new load
        if(!wasLoaded.current){
            window.addEventListener('wheel', handleScroll);
            window.addEventListener('touchstart', handleTouchStart)
            window.addEventListener('touchmove', handleTouchMove)
            // console.log("events added 1")
            document.body.style.overflowY = 'hidden';
            wasLoaded.current = true
        }
        return () => {
            document.body.style.overflowY = 'overflow';
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart)
            window.removeEventListener('touchmove', handleTouchMove)
            // console.log("events removed")
        };
      }, [handleScroll, handleTouchStart, handleTouchMove]);

      // add scroll events again, if scrolled back up
    useEffect(() => {
        const handleScrollPos = (event) => {
            const headers = headerRefs.current;
            
            scrollPosRef.current = window.scrollY

            // while scrolling below top of the page
            if(scrollPosRef.current > 0){
                wasPresented.current = true
            }
            // when reaching the top, reactivate presentation and add event
            if(wasPresented.current && scrollPosRef.current === 0){
                wasPresented.current = false
                wasLoaded.current = false
                window.addEventListener('wheel', handleScroll)
                window.addEventListener('touchstart', handleTouchStart)
                window.addEventListener('touchmove', handleTouchMove)
                // console.log("events added")
            }
        }
        // add this event for tracking scrolling
        window.addEventListener('wheel', handleScrollPos)
        window.addEventListener('touchmove', handleScrollPos)
        // cleanup
        return () => {
            document.body.style.overflowY = 'overflow';
            window.removeEventListener('wheel', handleScrollPos)
            window.removeEventListener('touchmove', handleScrollPos)
        }
    }, [handleScroll, handleTouchStart, handleTouchMove])

    return (
        <Container className="Home d-flex justify-content-center" >
            
            <Row className="align-items-center justify-content-center videoRow"  >
                <div className='videoAndHeaderContainer' ref={containerRef} style={{padding: '0' }}  data-aos="fade" data-aos-delay="200" data-aos-offset="0">
                {headersAndVideos.map(({ header, videoPath }, index) => (
                        <div
                            id={index}
                            key={index}
                            ref={(el) => (headerRefs.current[index] = el)}
                            style={{ 
                            visibility: index === activeIndex ? 'visible' : 'hidden', 
                            opacity: index === activeIndex ? '1' : '0' }}
                            className='videoAndHeaderSubContainer'
                        >
                            <VideoPlayer src={getVideoPath(videoPath, videoSource)} videoId={index} activeIndex={activeIndex} />
                            {header}
                        </div>
                        ))
                    }
                </div>
                
            </Row>
        </Container>
    )
}

export { Home as default }