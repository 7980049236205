import React from 'react'
import { Button, Row, Col, Image } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { HomeShareButtons } from './ShareButtons';

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);

const NavHome = () => <TranslatedMessage id="navHome" />
const NavFeatures = () => <TranslatedMessage id="navFeatures" />
const NavContact = () => <TranslatedMessage id="navContact" />
const DemoP1 = () => <TranslatedMessage id="demoP1" />
const DemoP2 = () => <TranslatedMessage id="demoP2" />
const DemoP3 = () => <TranslatedMessage id="demoP3" />

const Footer = () => {
    return (
        <Row className="Footer" >
            <Col className="mt-1 col-sm col-4 FooterLeft">
                <Image className="FooterLogo" src="/craneoptimizer-company-logo-white.png" /> 
                <div className="FooterLinks text-start" >
                    <Row className="mt-3"><a href="/"><NavHome/></a></Row>
                    <Row><a href="/#/features"><NavFeatures/></a></Row>
                    <Row><a href="/#/contact"><NavContact/></a></Row>
                </div>
            </Col>
            <Col className="FooterMiddle col-sm col-8">
            <footer>
                <p className="lead text-center fst-italic pt-3 mb-2">
                    <DemoP1/><span className="fw-bolder text-white" ><DemoP2/></span>?
                </p>
                <h2 className="lead text-center mb-4">
                    <DemoP3/>
                </h2>
                <section className="m-3">
                    
                </section>
                {/* <Button href="/#/contact" variant="primary" className="lead text-center">
                    Contact for access to the live dashboard
                </Button> */}
                <Button href="/#/contact" variant="primary" className="lead text-center">
                    <NavContact/>
                </Button>
                <HomeShareButtons/>
                <p className="mt-1 fst-italic CopyrightText">&copy;2025 | info@craneoptimizer.com</p>
            </footer>
            </Col>
            <Col className="d-flex justify-content-start align-items-center  FooterRight">
                
            </Col>
        </Row>
    )
}

export default Footer